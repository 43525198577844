import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { STORE_ROUTE } from 'router/routes';
import { CatalogContextInterface } from 'types/outlet_context_models';

const ElasticSearch = ({ inputValue, onChange }) => {
    const navigate = useNavigate();
    const { storeCode } = useParams();
    const [value, setValue] = useState(inputValue || '');
    const [matchedResult, setMatchedResult] = useState<any>([]);
    const [showSearch, setShowSearch] = useState(false);
    const { appXPadding, string, headerHeight }: CatalogContextInterface = useOutletContext();
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setValue(inputValue);
        setMatchedResult([]);
    }, [showSearch]); //eslint-disable-line

    return (
        <>
            <Box>
                <IconButton
                    sx={{
                        height: 30,
                        width: 30,
                        backgroundColor: '#fff',
                        mr: '2px',
                        border: '1px solid rgba(0, 0, 0, 0.120)',
                    }}
                    onClick={() => {
                        setShowSearch(!showSearch);
                    }}
                >
                    <SearchIcon />
                </IconButton>
            </Box>
            <Box
                sx={{
                    height: showSearch ? '100vh' : 0,
                    position: 'fixed',
                    width: '100vw',
                    maxWidth: 600,
                    top: 50,
                    zIndex: 4002,
                    right: 0,
                }}
                onClick={() => {
                    if (!value) setShowSearch(false);
                }}
            >
                <Box
                    onClick={e => {
                        e.stopPropagation();
                    }}
                    sx={{
                        height: showSearch ? `${headerHeight}px` : 0,
                        overflow: 'hidden',
                        transition: 'height 250ms cubic-bezier(0.4, 0, 0.2, 1)',
                        marginLeft: 'auto',
                        maxWidth: 600,
                    }}
                >
                    <Box
                        onClick={e => {
                            e.stopPropagation();
                        }}
                        pl={2}
                        pr={appXPadding}
                        mt={0.75}
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        {showSearch && (
                            <>
                                <TextField
                                    ref={inputRef}
                                    autoFocus={showSearch}
                                    value={value}
                                    onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                        setValue(event.target.value);
                                        onChange(event.target.value);
                                    }}
                                    onKeyUp={(event: KeyboardEvent<EventTarget>) => {
                                        const current = matchedResult.find(
                                            item => item.sku === (event!.target as HTMLInputElement)?.value
                                        );
                                        if (event.key === 'Enter' && current) {
                                            setShowSearch(false);
                                            setValue('');
                                            setMatchedResult([]);

                                            return navigate(
                                                STORE_ROUTE?.product(
                                                    storeCode,
                                                    current?.productId,
                                                    current.sku.replaceAll('/', '_')
                                                )
                                            );
                                        }
                                    }}
                                    sx={{
                                        backgroundColor: '#fff',
                                        mr: 1,
                                        webkitUserSelect: 'input !important',
                                        mb: 2,
                                        '.MuiInputBase-root': {
                                            height: 32,
                                        },
                                    }}
                                    fullWidth
                                    size="small"
                                    placeholder={string?.find_by_store_name}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment sx={{ cursor: 'pointer' }} position="end">
                                                <CloseIcon
                                                    onClick={() => {
                                                        setValue('');
                                                        setMatchedResult([]);
                                                        onChange('');
                                                    }}
                                                    fontSize="small"
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <IconButton
                                    onClick={() => {
                                        setShowSearch(false);
                                        setValue('');
                                        onChange('');
                                    }}
                                    size="small"
                                    sx={{
                                        border: '1px solid #ccc',
                                        width: 32,
                                        backgroundColor: '#fff',
                                        mb: 2,
                                        '&:hover': {
                                            backgroundColor: '#fff',
                                            opacity: 1,
                                        },
                                    }}
                                >
                                    <ArrowBackIosIcon sx={{ ml: 1 }} fontSize="small" />
                                </IconButton>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default ElasticSearch;
