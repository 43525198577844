import { Box } from '@mui/material';
import MobileNavButton from 'components/atoms/Buttons/MobileNavButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ProfileMenu from 'components/molecules/ToolsButtons/ProfileMenu';
import { useEffect, useState } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { STORE_ROUTE } from 'router/routes';
import { useParams } from 'react-router-dom';
import GridViewIcon from '@mui/icons-material/GridView';

const MobileMenu = ({
    appXPadding,
    string,
    auth,
    isShown,
    cart,
    favorites,
    headerHeight,
    user,
    menuHeight,
    handleOpenDialog,
}) => {
    const { storeCode } = useParams();

    const WINDOW_WIDTH = useWindowWidth();
    const [position, setPosition] = useState(0);

    useEffect(() => {
        setPosition(0);
    }, [WINDOW_WIDTH]);

    if (isShown)
        return (
            <Box
                px={appXPadding}
                sx={{
                    height: menuHeight,
                    position: 'fixed',
                    left: position,
                    bottom: position,
                    width: '100%',
                    background: '#000',
                    zIndex: 4000,
                }}
            >
                <Box
                    mt={0.5}
                    sx={{
                        height: menuHeight,
                        display: 'flex',
                        alignItems: 'baseline',
                        justifyContent: 'space-around',
                        gap: 2,
                    }}
                >
                    <MobileNavButton
                        path={STORE_ROUTE?.root(storeCode)}
                        title={string?.catalog}
                        icon={p => <GridViewIcon {...p} />}
                        childPath={['product']}
                    />
                    <MobileNavButton
                        path={STORE_ROUTE?.favorites(storeCode)}
                        title={string?.favorites}
                        icon={p => <FavoriteIcon {...p} />}
                        badgeCount={favorites?.favoriteItems?.filter(el => el?.storeCode === storeCode)?.length}
                    />

                    <MobileNavButton
                        path={STORE_ROUTE?.cart(storeCode)}
                        title={string?.cart}
                        icon={p => <ShoppingCartIcon {...p} />}
                        badgeCount={cart?.cartItems?.filter(el => el?.storeCode === storeCode)?.length}
                    />

                    <ProfileMenu
                        auth={auth}
                        string={string}
                        headerHeight={headerHeight}
                        footerMenuHeight={menuHeight}
                        user={user}
                        handleOpenDialog={handleOpenDialog}
                        childPath={['orders', 'profile', 'info', 'contacts']}
                    />
                </Box>
            </Box>
        );
    return null;
};

export default MobileMenu;
