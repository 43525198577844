import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Box, IconButton } from '@mui/material';

interface FavoriteButtonInterface {
    selected: boolean;
    onClick: () => void;
}

const FavoritesButton = ({ selected, onClick }: FavoriteButtonInterface) => {
    return (
        <Box>
            <Box>
                <IconButton
                    size="small"
                    sx={{
                        border: '1px solid #ed6c02',
                        backgroundColor: '#fff',
                        width: '33px',
                        height: '33px',
                    }}
                    onClick={e => {
                        e.stopPropagation();
                        onClick();
                    }}
                >
                    {selected ? <FavoriteIcon color="warning" /> : <FavoriteBorderIcon color="warning" />}
                </IconButton>
            </Box>
        </Box>
    );
};

export default FavoritesButton;
