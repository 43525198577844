function setStorageItem(key: string, value: string): Promise<void> {
    return new Promise((resolve, reject) => {
        try {
            localStorage.setItem(key, value);
            resolve();
        } catch (error) {
            console.error('Error setting localStorage item:', error);
            reject(error);
        }
    });
}

function getStorageItem(key: string): Promise<string | null> {
    return new Promise((resolve, reject) => {
        try {
            const value = localStorage.getItem(key);
            resolve(value);
        } catch (error) {
            console.error('Error getting localStorage item:', error);
            reject(error);
        }
    });
}

function removeStorageItem(key: string): Promise<void> {
    return new Promise((resolve, reject) => {
        try {
            localStorage.removeItem(key);
            resolve();
        } catch (error) {
            console.error('Error removing localStorage item:', error);
            reject(error);
        }
    });
}

export { setStorageItem, getStorageItem, removeStorageItem };
