import Grid from '@mui/material/Unstable_Grid2';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { STORE_TYPES } from 'store_constants/useGetStoreTypes';
import { Color } from 'constants/colors';
import { useDevice } from 'hooks/useDevice';
import { StoresListContextInterface } from 'types/outlet_context_models';
import { useOutletContext } from 'react-router-dom';

const TypesFilter = ({ setFilterTypes, filterTypes }) => {
    const { lang }: StoresListContextInterface = useOutletContext();
    const { sx } = useDevice();

    const handleSquareClick = (id: number, similarTypes, isSelected) => {
        if (isSelected) {
            const filters = filterTypes?.filter(el => el !== id && !similarTypes?.includes(el));
            if (filters?.length) {
                return setFilterTypes(filters);
            } else {
                setFilterTypes(null);
            }
        } else {
            if (!filterTypes) {
                return setFilterTypes([id, ...similarTypes]);
            }
            if (filterTypes?.length) setFilterTypes([...filterTypes, id, ...similarTypes]);
        }
    };

    const isSelected = type => filterTypes?.includes(type);

    return (
        <Grid
            pr={3}
            container
            sx={{
                flexWrap: 'nowrap !important',
                minWidth: '100vw',
                overflowX: 'auto',
                whiteSpace: 'nowrap',
                py: 1,
                gap: sx ? 1 : 1.5,
                zIndex: 1,
            }}
        >
            {STORE_TYPES?.filter(el => el?.active).map(type => (
                <Square
                    key={type.id}
                    isSelected={isSelected(type?.id)}
                    onClick={() => handleSquareClick(type.id, type?.similarTypes, isSelected(type?.id))}
                    sx={{
                        minWidth: sx ? 80 : '100px',
                        maxWidth: sx ? 80 : '100px',
                        height: sx ? 110 : '135px',
                        textAlign: 'center',
                        boxShadow: '0 0 2px 2px #cccccc49',
                    }}
                >
                    <Box
                        component="img"
                        src={type.image}
                        alt={type.name[0].name}
                        sx={{ width: '100%', height: 'auto' }}
                    />
                    <Typography
                        color="white"
                        sx={{
                            px: 0.25,
                            fontSize: sx ? 12 : 13,
                            height: sx ? 30 : 35,
                            display: 'flex',
                            alignItems: 'center',
                            whiteSpace: 'wrap',
                            justifyContent: 'center',
                        }}
                    >
                        {type.name.find(el => el?.lang === lang)?.name}
                    </Typography>
                </Square>
            ))}
        </Grid>
    );
};

const Square = styled(Box, {
    shouldForwardProp: prop => prop !== 'isSelected',
})<{ isSelected: boolean }>(({ isSelected }) => ({
    backgroundColor: isSelected ? Color.PRIMARY : Color.SECONDARY,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.3s, transform 0.3s',
    color: 'white',
    fontWeight: 'bold',
    flexDirection: 'column',
    borderRadius: 8,
    overflow: 'hidden',
    filter: isSelected ? 'none' : 'grayscale(100%)',
}));

export default TypesFilter;
