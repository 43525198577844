import { Box } from '@mui/material';
import MobileNavButton from 'components/atoms/Buttons/MobileNavButton';
import HomeIcon from '@mui/icons-material/Home';
import ProfileMenu from 'components/molecules/ToolsButtons/ProfileMenu';
import { useEffect, useState } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { HOME_ROUTE, MARKET_ROUTE } from 'router/routes';
import { telegramSender } from 'utils/telegramSender';
import LocalBarTwoToneIcon from '@mui/icons-material/LocalBarTwoTone';
// import BookmarkIcon from '@mui/icons-material/Bookmark';
// import { useNavigate } from 'react-router-dom';

const HomeMobileMenu = ({
    appXPadding,
    menuHeight,
    isShown,
    string,
    handleOpenDialog,
    headerHeight,
    user,
    auth,
    subscribeList,
}) => {
    const WINDOW_WIDTH = useWindowWidth();
    // const navigate = useNavigate();
    const [position, setPosition] = useState(0);

    useEffect(() => {
        setPosition(0);
    }, [WINDOW_WIDTH]);

    if (isShown)
        return (
            <Box
                px={appXPadding}
                sx={{
                    height: menuHeight,
                    position: 'fixed',
                    left: 0,
                    bottom: position,
                    width: '100%',
                    background: '#000',
                    zIndex: 4000,
                }}
            >
                <Box
                    mt={0.5}
                    sx={{
                        height: menuHeight,
                        display: 'flex',
                        alignItems: 'baseline',
                        justifyContent: 'space-around',
                        gap: 2,
                    }}
                >
                    <MobileNavButton path={HOME_ROUTE?.root()} title={string?.home} icon={p => <HomeIcon {...p} />} />

                    <MobileNavButton
                        path={MARKET_ROUTE?.root()}
                        title={string?.stores}
                        icon={p => <LocalBarTwoToneIcon {...p} />}
                        childPath={[]}
                        action={() => {
                            telegramSender({
                                action: `ЗАШЕЛ НА МАРКЕТ`,
                            });
                        }}
                    />

                    {/* <MobileNavButton
                        path={MARKET_ROUTE?.subscribes()}
                        title={string?.subscribes}
                        icon={p => <BookmarkIcon {...p} />}
                        childPath={[]}
                        action={() => {
                            navigate(MARKET_ROUTE?.subscribes());
                        }}
                        badgeCount={subscribeList?.length}
                    /> */}

                    <ProfileMenu
                        auth={auth}
                        string={string}
                        headerHeight={headerHeight}
                        footerMenuHeight={menuHeight}
                        user={user}
                        handleOpenDialog={handleOpenDialog}
                        childPath={['orders', 'profile', 'info', 'contacts']}
                    />
                </Box>
            </Box>
        );
    return null;
};

export default HomeMobileMenu;
