import { useEffect, useState } from 'react';
import { RETAIL_ALBERTO_BINI } from './stores_configs/RETAIL_ALBERTO_BINI';
import { StoreType, STORE_CONFIG_Interface, ViewModeType } from './types';
import { PASMA_DESIGN } from './stores_configs/PASMA_DESIGN';
import { YOU_BEST } from './stores_configs/YOU_BEST';
import { IGLE_STORE } from './stores_configs/IGLE_STORE';
import { KREMEN_BELTS } from './stores_configs/KREMEN_BELTS';
import { ALBERTO_BINI_SALES } from './stores_configs/ALBERTO_BINI_SALES';
import { COCKTAIL_STORE } from './stores_configs/COCKTAIL_STORE';
import { SASSOFONO } from './stores_configs/SASSOFONO';
import { HM_AlbertoBini } from './stores_configs/HM_AlbertoBini';

export const APP_STORES = [
    RETAIL_ALBERTO_BINI,
    YOU_BEST,
    PASMA_DESIGN,
    IGLE_STORE,
    KREMEN_BELTS,
    ALBERTO_BINI_SALES,
    COCKTAIL_STORE,
    SASSOFONO,
    HM_AlbertoBini,
];

export const DropLinks = [
    'https://cocktaile-shop.netlify.app',
    'http://localhost:3000',
    'https://cocktail-catalogs-shop.com',
    'https://cocktail-catalogs-shop.netlify.app',
];

export const useStoreConfig = (): STORE_CONFIG_Interface => {
    const [currentStore, setCurrentStore] = useState<any | null>(null);

    // Extract the last part of the path (store code)
    const pathSegments = window.location.pathname.split('/');
    const storeCode = pathSegments[pathSegments.length - 1];

    // Type guard to ensure the store matches the expected interface
    const isStoreConfig = (store: any): store is STORE_CONFIG_Interface => {
        return store && typeof store.store_code === 'string' && typeof store.store_name === 'string';
    };

    useEffect(() => {
        if (!storeCode) return;

        const matchedStore = APP_STORES.find(store => store.store_code.includes(storeCode)) as
            | STORE_CONFIG_Interface
            | undefined;

        // Ensure the matched store has the required shape
        if (isStoreConfig(matchedStore)) {
            setCurrentStore(matchedStore);
        } else {
            setCurrentStore(null);
        }
    }, [storeCode]);

    return {
        STORAGE_KEY: currentStore?.storage_key || 'sn_def',
        ACTIVE: currentStore?.active || false,
        STATUS: currentStore?.status || null,
        REQUIRED_REGISTRATION: currentStore?.required_registration || false,
        STORE_NAME: currentStore?.store_name || '',
        STORE_CODE: currentStore?.store_code || '',
        BASE_URL: currentStore?.base_url || 'https://kremen-belts.com/api',
        APP_LANGUAGE: currentStore?.app_lang || 'ua',
        HTML_LANG: currentStore?.web_lang || 'uk',
        SUPPORTED_COUNTRIES: currentStore?.supported_countries || null,
        SIDE_LINKS: currentStore?.links || [],
        TELEGRAM_SENDER: currentStore?.telegram_sender || false,
        MANAGERS: currentStore?.managers || [
            {
                firstName: 'Cocktail Manager',
                lastName: '',
                emailAddress: 'salesnest.info@gmail.com',
                options: { manager: true },
                contacts: {
                    emailAddress: 'salesnest.info@gmail.com',
                    phone: '+380668652127',
                    viber: '',
                    whatsapp: '+380668652127',
                    telegram: 'SNBusinessApp',
                },
            },
        ],
        OPTIONS: {
            DISCOUNTS: currentStore?.options.discounts || false,
            TELEGRAM_BOT: currentStore?.options?.telegram_bot || null,
            PRODUCT_IMAGE_OPTIONS: currentStore?.options?.product_image_options || { width: 1, height: 1 },
            MAIN_PRICE_MULTIPLICATION: currentStore?.options?.main_price_multiplication || 1,
            SALE_PRICE_MULTIPLICATION: currentStore?.options?.sale_price_multiplication || 1,
            CURRENCY_MULTIPLICATION: currentStore?.options?.currency_multiplication || 1,
            RETAIL_PRICE_MULTIPLICATION: currentStore?.options?.retail_price_multiplication || 1,
            CUSTOM_CURRENCY: currentStore?.options?.custom_currency || null,
            MIN_ITEMS_TO_BUY: currentStore?.options?.min_items_to_buy || 1,
            STORE_TYPE: currentStore?.options?.store_type || StoreType?.default,
            HOME_PAGE_ACTIVE: currentStore?.options?.home_page_active || false,
            INFORMATION_PAGE_ACTIVE: currentStore?.options?.informative_page_active || false,
            CUSTOM_LOGO: currentStore?.options?.custom_logo || false,
            PLAN_OPTIONS: currentStore?.options?.plan_options || {
                prices: false,
                sizes: false,
                contacts: false,
                feedback: false,
                categories: false,
                productShare: false,
                cart: false,
                favorites: false,
                tableSizes: false,
                marketPlace: { isShown: false, type: null },
            },
        },
        WEB_HEAD_DATA: {
            STORE_TITLE: currentStore?.web_head_data?.store_title || '',
            STORE_DESCRIPTION: currentStore?.web_head_data?.store_description || '',
            GOOGLE_ANALYTICS_ID: currentStore?.web_head_data?.google_analytics_id || '',
            GOOGLE_ADS_ID: currentStore?.web_head_data?.google_ads_id || '',
            CONVERSION_ID: currentStore?.web_head_data?.conversion_id || '',
            STORE_LOGO: currentStore?.web_head_data?.store_logo || '',
            STORE_POSTER: currentStore?.web_head_data?.store_poster || '',
            KEYWORDS: currentStore?.web_head_data?.keywords || {},
        },
        USER_OPTIONS: { VIEW_MODE: currentStore?.user_options?.view_mode || ViewModeType?.card },
        ALERTS: currentStore?.alerts || [],
        DESCRIPTIONS: currentStore?.store?.descriptions || [],
        PRODUCT_TYPES: currentStore?.store?.types || [],
        GENDER: currentStore?.store?.gender,
        RANK: currentStore?.store?.rank,
    };
};
