import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query';
import useApi from './useApi';
import { AxiosResponse } from 'axios';
import { Store_Data_Response_Interface, Stores_List_Response_Interface } from 'types/response_models';

interface GetAllStoresParams {
    count: number;
    page: number;
}

export const useStoresApi = () => {
    const { get, post, remove } = useApi();

    const useGetAllStores = ({
        count = 10,
        page = 0,
    }: GetAllStoresParams): UseQueryResult<AxiosResponse<Stores_List_Response_Interface[]>, unknown> => {
        return useQuery(
            ['get-all-stores', count, page],
            () =>
                get({
                    url: `/v1/stores?count=${count}&page=${page}`,
                })
            // { cacheTime: 1000 * 60 * 5 }
        );
    };

    const useGetAllFavoritesStores = ({ auth }) => {
        return useQuery(
            ['get-all-favorites-stores'],

            () =>
                get({
                    url: `v1/auth/favoriteStores`,
                }),
            { enabled: !!auth, retryOnMount: false }
        );
    };

    const useAddStoreToFavorite = () =>
        useMutation(({ storeCode }: any) => {
            return post({
                url: `v1/auth/favoriteStores/${storeCode}`,
            });
        });

    const useDeleteStoreToFavorite = () =>
        useMutation(({ storeCode }: any) => {
            return remove({
                url: `v1/auth/favoriteStores/${storeCode}`,
            });
        });

    const useGetStoreByCode = ({ code }): UseQueryResult<AxiosResponse<Store_Data_Response_Interface>, unknown> => {
        return useQuery(['get-store-by-code', code], () => get({ url: `v1/store/${code}` }), {
            enabled: !!code,
        });
    };

    return {
        useGetAllStores,
        useAddStoreToFavorite,
        useGetAllFavoritesStores,
        useDeleteStoreToFavorite,
        useGetStoreByCode,
    };
};
