import { useEffect, useState } from 'react';

// Интерфейс для свойств компонента
interface Props {
    lang: string; // Язык для названий типов
    storeTypes?: number[]; // Список идентификаторов типов магазина
}

// Интерфейс для типа магазина
interface StoreTypeInterface {
    id: number; // Идентификатор типа
    code: string; // Код типа
    name: { lang: string; name: string }[]; // Названия типов на разных языках
    active: boolean; // Активен ли тип
    image: string; // Путь к изображению типа
    similarTypes: number[]; // Список идентификаторов похожих типов
}

// Массив с типами одежды
export const STORE_TYPES: StoreTypeInterface[] = [
    {
        id: 3,
        code: 'SHIRT',
        name: [
            { lang: 'ua', name: 'Сорочки' },
            { lang: 'en', name: 'Shirts' },
        ],
        active: true,
        similarTypes: [],
        image: require('./types_imgs/shirt.webp'),
    },
    {
        id: 4,
        code: 'PANTS',
        name: [
            { lang: 'ua', name: 'Штани' },
            { lang: 'en', name: 'Pants' },
        ],
        active: true,
        similarTypes: [12, 57],
        image: require('./types_imgs/pants.webp'),
    },
    {
        id: 5,
        code: 'JACKET',
        name: [
            { lang: 'ua', name: 'Куртки' },
            { lang: 'en', name: 'Jackets' },
        ],
        active: true,
        similarTypes: [23, 43],
        image: require('./types_imgs/jacket.webp'),
    },
    {
        id: 6,
        code: 'DRESS',
        name: [
            { lang: 'ua', name: 'Сукні' },
            { lang: 'en', name: 'Dresses' },
        ],
        active: true,
        similarTypes: [],
        image: require('./types_imgs/dress.webp'),
    },
    {
        id: 12,
        code: 'JEANS',
        name: [
            { lang: 'ua', name: 'Джинси' },
            { lang: 'en', name: 'Jeans' },
        ],
        active: false,
        similarTypes: [4],
        image: require('./types_imgs/pants.webp'),
    },
    {
        id: 13,
        code: 'SKIRT',
        name: [
            { lang: 'ua', name: 'Спідниці' },
            { lang: 'en', name: 'Skirts' },
        ],
        active: true,
        similarTypes: [],
        image: require('./types_imgs/skirt.webp'),
    },
    {
        id: 14,
        code: 'SWEATER',
        name: [
            { lang: 'ua', name: 'Светри' },
            { lang: 'en', name: 'Sweaters' },
        ],
        active: true,
        similarTypes: [],
        image: require('./types_imgs/sweater.webp'),
    },
    {
        id: 15,
        code: 'BLAZER',
        name: [
            { lang: 'ua', name: 'Піджаки' },
            { lang: 'en', name: 'Blazers' },
        ],
        active: true,
        similarTypes: [],
        image: require('./types_imgs/blazer.webp'),
    },
    {
        id: 17,
        code: 'COAT',
        name: [
            { lang: 'ua', name: 'Пальта' },
            { lang: 'en', name: 'Coats' },
        ],
        active: true,
        similarTypes: [],
        image: require('./types_imgs/coat.webp'),
    },
    {
        id: 23,
        code: 'LEATHER_JACKET',
        name: [
            { lang: 'ua', name: 'Куртки косухи' },
            { lang: 'en', name: 'Leather Jackets' },
        ],
        active: false,
        similarTypes: [5],
        image: require('./types_imgs/jacket.webp'),
    },
    {
        id: 31,
        code: 'VEST',
        name: [
            { lang: 'ua', name: 'Жилети' },
            { lang: 'en', name: 'Vests' },
        ],
        active: true,
        similarTypes: [],
        image: require('./types_imgs/vest.webp'),
    },
    {
        id: 36,
        code: 'TRENCH_COAT',
        name: [
            { lang: 'ua', name: 'Тренчкоти' },
            { lang: 'en', name: 'Trench Coats' },
        ],
        active: false,
        similarTypes: [17],
        image: require('./types_imgs/main_logo.webp'),
    },
    {
        id: 39,
        code: 'HOODIE',
        name: [
            { lang: 'ua', name: 'Худі' },
            { lang: 'en', name: 'Hoodies' },
        ],
        active: false,
        similarTypes: [14],
        image: require('./types_imgs/sweater.webp'),
    },
    {
        id: 42,
        code: 'PULLOVER',
        name: [
            { lang: 'ua', name: 'Пуловери' },
            { lang: 'en', name: 'Pullovers' },
        ],
        active: false,
        similarTypes: [14],
        image: require('./types_imgs/sweater.webp'),
    },
    {
        id: 43,
        code: 'BOMBER_JACKET',
        name: [
            { lang: 'ua', name: 'Бомбери' },
            { lang: 'en', name: 'Bomber Jackets' },
        ],
        active: false,
        similarTypes: [14],
        image: require('./types_imgs/sweater.webp'),
    },
    {
        id: 44,
        code: 'TRACKSUIT',
        name: [
            { lang: 'ua', name: 'Спортивні костюми' },
            { lang: 'en', name: 'Tracksuits' },
        ],
        active: true,
        similarTypes: [],
        image: require('./types_imgs/costume_sport.webp'),
    },
    {
        id: 45,
        code: 'CARDIGAN',
        name: [
            { lang: 'ua', name: 'Кардигани' },
            { lang: 'en', name: 'Cardigans' },
        ],
        active: true,
        similarTypes: [],
        image: require('./types_imgs/cardigan.webp'),
    },
    {
        id: 51,
        code: 'BUSINESS_SUIT',
        name: [
            { lang: 'ua', name: 'Ділові костюми' },
            { lang: 'en', name: 'Business Suits' },
        ],
        active: true,
        similarTypes: [57],
        image: require('./types_imgs/suit_bus.webp'),
    },
    {
        id: 57,
        code: 'PANTSUIT',
        name: [
            { lang: 'ua', name: 'Брючні костюми' },
            { lang: 'en', name: 'Pantsuits' },
        ],
        active: true,
        similarTypes: [51],
        image: require('./types_imgs/pant_suit.webp'),
    },
    {
        id: 1,
        code: 'HAT',
        name: [
            { lang: 'ua', name: 'Капелюхи' },
            { lang: 'en', name: 'Hats' },
        ],
        active: true,
        similarTypes: [],
        image: require('./types_imgs/hat.webp'),
    },
    {
        id: 2,
        code: 'BELT',
        name: [
            { lang: 'ua', name: 'Ремені' },
            { lang: 'en', name: 'Belts' },
        ],
        active: true,
        similarTypes: [],
        image: require('./types_imgs/belt.webp'),
    },
    {
        id: 62,
        code: 'SHEEPSKIN_COAT',
        name: [
            { lang: 'ua', name: 'Дублянки' },
            { lang: 'en', name: 'Sheepskin Coats' },
        ],
        active: true,
        similarTypes: [],
        image: require('./types_imgs/shearling_coat.webp'),
    },
    {
        id: 63,
        code: 'FUR_COAT',
        name: [
            { lang: 'ua', name: 'Шуби' },
            { lang: 'en', name: 'Fur Coats' },
        ],
        active: true,
        similarTypes: [],
        image: require('./types_imgs/fur_coat.webp'),
    },
    {
        id: 64,
        code: 'RAGLAN',
        name: [
            { lang: 'ua', name: 'Реглани' },
            { lang: 'en', name: 'Raglans' },
        ],
        active: false,
        similarTypes: [14],
        image: require('./types_imgs/sweater.webp'),
    },
    {
        id: 1000,
        code: 'ELSE',
        name: [
            { lang: 'ua', name: 'Iнше' },
            { lang: 'en', name: 'Else' },
        ],
        active: false,
        similarTypes: [],
        image: require('./types_imgs/main_logo.webp'),
    },
];

// Хук для получения типов магазина
const useGetStoreTypes = ({ lang, storeTypes }: Props) => {
    const [currentStoreTypes, setCurrentStoreTypes] = useState<string[]>([]);
    const [listTypes, setListTypes] = useState<string[]>([]);

    useEffect(() => {
        setCurrentStoreTypes(
            STORE_TYPES.filter(type => storeTypes?.includes(type.id)).map(
                type => type.name.find(n => n.lang === lang)?.name || ''
            )
        );
        setListTypes(STORE_TYPES.map(type => type.name.find(n => n.lang === lang)?.name || ''));
    }, [lang, storeTypes]); // eslint-disable-line

    return { currentStoreTypes, listTypes };
};

export default useGetStoreTypes;
