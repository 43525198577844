import { GenderType, MarketPlaceType, StoreType, ViewModeType } from 'store_constants/types';

export const IGLE_STORE = {
    storage_key: 'igle_store',
    active: true, // Поле которое запускает магазин true - работает false - остановлен / по тех. причина
    status: null,
    required_registration: false,
    URL: [],
    web_lang: 'uk', // html lang
    store_name: 'IGLE',
    store_code: 'igle_store',
    base_url: 'https://kremen-belts.com/api',
    app_lang: 'ua',
    supported_countries: null,
    telegram_sender: true,
    links: [],
    managers: [
        {
            firstName: 'Cocktail Manager',
            lastName: '',
            emailAddress: 'salesnest.info@gmail.com',
            options: { manager: true },
            contacts: {
                emailAddress: 'salesnest.info@gmail.com',
                phone: '+380668652127',
                viber: '',
                whatsapp: '+380668652127',
                telegram: 'SNBusinessApp',
            },
        },
    ],
    options: {
        discounts: false,
        webUrl: null,
        telegram_bot: [
            {
                token: '7115237316:AAFgbrVadLWMkPt3TEdmravEa37jO9N3WSQ',
                chatId: '480774886',
            },
        ],
        product_image_options: { width: 6.75, height: 9 },
        main_price_multiplication: 1,
        sale_price_multiplication: 1,
        currency_multiplication: 1,
        retail_price_multiplication: 1,
        min_items_to_buy: 1,
        store_type: StoreType?.sales,
        home_page_active: true,
        informative_page_active: true,
        custom_currency: null,
        custom_logo: false,
        orderPriority: 60,
        plan_options: {
            prices: true,
            sizes: true,
            contacts: true,
            feedback: true,
            categories: true,
            productShare: true,
            cart: true,
            favorites: true,
            tableSizes: true,
            marketPlace: { isShown: true, type: MarketPlaceType?.drop },
        },
    },
    web_head_data: {
        store_title: '',
        store_description: ``,
        google_analytics_id: 'G-SPMQE8GHGM',
        google_ads_id: '',
        conversion_id: '',
        store_logo: '',
        store_poster: '',
        keywords: {},
    },
    user_options: { view_mode: ViewModeType?.card },
    alerts: [],
    store: {
        bannerUrl: require(`../banners/igle_store.webp`),
        descriptions: [
            {
                language: 'ua',
                description:
                    'Наш жіночий магазин пропонує широкий асортимент стильного одягу та аксесуарів для сучасних жінок. Ми прагнемо забезпечити якість і комфорт у кожному виробі, щоб ви могли виглядати і почуватися бездоганно. В нашій колекції ви знайдете все необхідне, щоб підкреслити вашу індивідуальність та стиль, від повсякденного до святкового одягу.',
            },
            {
                language: 'en',
                description:
                    "Our women's store offers a wide range of stylish clothing and accessories for modern women. We strive to provide quality and comfort in every piece, ensuring you look and feel flawless. In our collection, you'll find everything you need to highlight your individuality and style, from everyday wear to festive outfits.",
            },
        ],
        types: [62, 43, 14, 5, 23, 61, 3, 1000],
        gender: [GenderType?.FEMALE],
        rank: 3,
    },
};
