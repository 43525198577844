export const LONG_PHRASES = {
    en: {
        before_ordering_enter_phone_number_or: 'Before ordering, please enter your phone number or',
        registering: 'Register',

        something_went_wrong: 'Something went wrong, please try again',
        question_form_message:
            'If you have any questions or difficulties, please do not hesitate to contact us. We will make every effort to respond to you as quickly as possible.',
        call_back_messageA:
            'Hello! If you have questions, comments or need assistance, please contact us. We are ready to help you with your choice, provide you with additional information about the product or help you place your order.',
        call_back_messageB: 'You can contact us in any messenger available or by phone.',
        call_back_messageC: 'Thank you for your interest in our online catalogue!',
        wholesales_ordering_limitation_message:
            'Please note that a minimum purchase of 10 units is required for each order from our wholesale catalog. Orders for fewer than 10 units cannot be processed through this catalog. For smaller quantities, please refer to our retail catalog.',
        user_with_this_email_not_found: 'User with this email not found',
        manger_will_contact_message:
            'Our manager will be in touch with you shortly to provide detailed information regarding your purchase.',

        please_enter_your_email_address_you_will_receive_a_link_to_create_a_new_password_via_email:
            'Please enter your email address. You will receive a link to create a new password via email.',
        an_email_with_a_link_has_been_sent_to_your_email: 'An email with a link has been sent to your email',
        min_purchase: 'Minimum Purchase Requirement',
        not_required_data_filling: `Filling out the form is optional—we'll reach out to you regardless. However, providing your details would be a great help in speeding up your order.`,
        size_selection_is_only_available_during_checkout: `In this section, you can see the sizes available for this model. You can easily select the one you need during checkout.`,
    },

    ua: {
        before_ordering_enter_phone_number_or:
            'Перед оформленням замовлення, будь ласка, введіть свій номер телефону або',
        registering: 'Зареєструйтеся',
        something_went_wrong: 'Щось пішло не так, будь ласка, спробуйте пізніше!',
        question_form_message:
            'Якщо у вас виникли питання або труднощі, будь ласка, не соромтеся звертатися до нас. Ми докладемо всіх зусиль, щоб відповісти вам якомога швидше.',
        call_back_messageA:
            'Привіт! Якщо у вас є запитання, коментарі або потрібна допомога, зв’яжіться з нами. Ми готові допомогти вам з вибором, надати додаткову інформацію про товар або допомогти оформити замовлення.',
        call_back_messageB: 'Ви можете зв’язатися з нами в будь-якому доступному месенджері або по телефону.',
        call_back_messageC: 'Дякуємо за інтерес до нашого онлайн-каталогу!',
        min_purchase: 'Мінімальна Вимога до Закупівлі',
        please_enter_your_email_address_you_will_receive_a_link_to_create_a_new_password_via_email:
            'Будь ласка, введіть адресу електронної пошти. Ви отримаєте електронною поштою посилання для створення нового пароля.',
        an_email_with_a_link_has_been_sent_to_your_email: 'На Вашу електронну пошту надіслано листа з посиланням',
        user_with_this_email_not_found: 'Користувача з такою електронною поштою не знайдено',
        wholesales_ordering_limitation_message:
            'Зверніть увагу, що мінімальний обсяг замовлення у нашому оптовому каталозі становить 10 одиниць. Замовлення на меншу кількість одиниць не можуть бути оброблені через цей каталог. Для придбання меншої кількості, будь ласка, зверніться до нашого роздрібного каталогу.',
        manger_will_contact_message: `Наш менеджер зв'яжеться з вами найближчим часом, щоб надати детальну інформацію щодо вашої покупки.`,
        not_required_data_filling: `Заповнювати форму не обов’язково—ми все одно зв'яжемося з вами. Однак надання ваших даних буде великим плюсом для прискорення виконання замовлення.`,
        size_selection_is_only_available_during_checkout: `У цьому розділі ви бачите доступні для цієї моделі розміри. Вибрати потрібний вам розмір ви зможете легко під час оформлення замовлення.`,
    },
};
