import { GenderType, MarketPlaceType, StoreType, ViewModeType } from 'store_constants/types';

export const COCKTAIL_STORE = {
    storage_key: 'cocktail_store',
    active: false, // Поле которое запускает магазин true - работает false - остановлен / по тех. причина
    status: null,
    required_registration: false,
    URL: [],
    web_lang: 'uk', // html lang
    store_name: 'Cocktail Store',
    store_code: 'cocktail_store',
    base_url: 'https://kremen-belts.com/api',
    app_lang: 'ua',
    supported_countries: null,
    telegram_sender: true,
    links: [],
    managers: [
        {
            firstName: 'Cocktail Manager',
            lastName: '',
            emailAddress: 'salesnest.info@gmail.com',
            options: { manager: true },
            contacts: {
                emailAddress: 'salesnest.info@gmail.com',
                phone: '+380668652127',
                viber: '',
                whatsapp: '+380668652127',
                telegram: 'SNBusinessApp',
            },
        },
    ],
    options: {
        discounts: false,
        webUrl: null,
        telegram_bot: [
            {
                token: '7115237316:AAFgbrVadLWMkPt3TEdmravEa37jO9N3WSQ',
                chatId: '480774886',
            },
        ],
        product_image_options: { width: 6.75, height: 9 },
        main_price_multiplication: 1,
        sale_price_multiplication: 1,
        currency_multiplication: 1,
        retail_price_multiplication: 1,
        min_items_to_buy: 1,
        store_type: StoreType?.sales,
        home_page_active: true,
        informative_page_active: true,
        custom_currency: null,
        custom_logo: false,
        orderPriority: 10,
        plan_options: {
            prices: true,
            sizes: true,
            contacts: true,
            feedback: true,
            categories: true,
            productShare: true,
            cart: true,
            favorites: true,
            tableSizes: true,
            marketPlace: { isShown: true, type: MarketPlaceType?.drop },
        },
    },
    web_head_data: {
        store_title: '',
        store_description: ``,
        google_analytics_id: 'G-SPMQE8GHGM',
        google_ads_id: '',
        conversion_id: '',
        store_logo: '',
        store_poster: '',
        keywords: {},
    },
    user_options: { view_mode: ViewModeType?.card },
    alerts: [],
    store: {
        bannerUrl: require(`../banners/cocktail_store.webp`),
        descriptions: [
            {
                language: 'ua',
                description:
                    'Cocktail — це наш фірмовий магазин, який пропонує стильний одяг та аксесуари для всіх. Ми ретельно відбираємо кожен виріб, щоб забезпечити комфорт і високу якість. У нашій колекції ви знайдете речі для будь-якого випадку, які підкреслять вашу індивідуальність та допоможуть створити бездоганний образ.',
            },
            {
                language: 'en',
                description:
                    'Cocktail is our signature store offering stylish clothing and accessories for everyone. We carefully select each item to ensure comfort and high quality. In our collection, you’ll find pieces for any occasion that highlight your individuality and help you create a flawless look.',
            },
        ],
        types: [62, 43, 14, 5, 23, 61, 3, 1000],
        gender: [GenderType?.FAMILY],
        rank: 1,
    },
};
