import { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useDevice } from 'hooks/useDevice';
import { Color, Colors } from 'constants/colors';

interface Props {
    title: string;
    font?: string;
    imgUrl: string;
    custom?: boolean;
}

const specialWords = ['outlet', 'sale', 'sales', 'discount'];

const HeaderLogo = ({ title, imgUrl, custom = false }: Props) => {
    const { xxs, xxxs } = useDevice();
    const [imgLoaded, setImgLoaded] = useState(false);
    const [imgError, setImgError] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = imgUrl;
        img.onload = () => {
            setImgLoaded(true);
        };
        img.onerror = () => {
            setImgLoaded(true);
            setImgError(true);
        };

        img.onload = () => {
            setImgLoaded(true);
        };

        return () => {
            img.onload = null;
            img.onerror = null;
        };
    }, [imgUrl]);

    const words = title?.split(' ');

    const titleComponents = words?.map((word, index) => {
        const isSpecial = specialWords?.includes(word.toLowerCase());

        return (
            <Typography
                key={index}
                component="span"
                sx={{
                    fontSize: xxxs ? 18 : xxs ? 24 : 32,
                    fontWeight: 700,
                    lineHeight: 1,
                    color: isSpecial ? Colors?.RED_300 : Colors?.GRAY_900,
                    fontFamily: 'Serif',
                }}
            >
                {word}{' '}
            </Typography>
        );
    });

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: xxxs ? 36 : xxs ? 42 : 48,
                cursor: 'pointer',
            }}
        >
            {imgLoaded && !imgError ? (
                <img
                    src={imgUrl}
                    style={{
                        width: '100%',
                        height: 'auto',
                        border: '1px solid #ccc',
                        borderRadius: 8,
                    }}
                    alt="img"
                />
            ) : (
                <Box>
                    <CircularProgress sx={{ color: Color?.SECONDARY }} thickness={1} />
                </Box>
            )}
            <Box
                ml={1}
                sx={{ width: 'fit-content', display: 'flex', alignItems: 'center', flexWrap: 'nowrap', gap: 0.45 }}
            >
                {titleComponents}
            </Box>
        </Box>
    );
};

export default HeaderLogo;
