import InfoDialog from 'components/organisms/Modals/InfoDialog';
import { HOME_ROUTE, LOGIN_ROUTE, STORE_ROUTE } from 'router/routes';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreConfig } from 'store_constants/useStoreConfig';

const SuccessPasswordChange = ({ isOpen, string }) => {
    const { storeCode } = useParams();
    const navigate = useNavigate();
    const { REQUIRED_REGISTRATION, OPTIONS } = useStoreConfig();
    if (!isOpen) return null;

    return (
        <>
            <InfoDialog
                variant="success"
                link={null}
                string={string}
                onClose={() => {
                    if (REQUIRED_REGISTRATION) {
                        navigate(LOGIN_ROUTE?.root(storeCode, 'login'));
                    } else if (OPTIONS?.HOME_PAGE_ACTIVE) {
                        navigate(HOME_ROUTE?.root());
                    } else navigate(STORE_ROUTE?.root(storeCode));
                }}
                title={null}
                fullWidth
                onSubmit={() => {
                    if (REQUIRED_REGISTRATION) {
                        navigate(LOGIN_ROUTE?.root(storeCode, 'login'));
                    } else if (OPTIONS?.HOME_PAGE_ACTIVE) {
                        navigate(HOME_ROUTE?.root());
                    } else navigate(STORE_ROUTE?.root(storeCode));
                }}
                description={string?.password_changed}
                closeAvailable
                component="success request"
                content={null}
            />
        </>
    );
};

export default SuccessPasswordChange;
