import { useEffect } from 'react';
import ErrorComponent from './ErrorComponent';
import { telegramSender } from 'utils/telegramSender';
import { useStoreConfig } from 'store_constants/useStoreConfig';

const PAGE_500 = () => {
    const { STATUS } = useStoreConfig();

    useEffect(() => {
        if (STATUS) telegramSender({ action: STATUS });
    }, []); //eslint-disable-line

    return <ErrorComponent code="500" title="SERVER ERROR" withLink={false} />;
};

export default PAGE_500;
