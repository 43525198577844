import FilterListOffOutlinedIcon from '@mui/icons-material/FilterListOffOutlined';
import { Box, IconButton } from '@mui/material';

interface ClearFilterButtonInterface {
    disabled: boolean;
    action?: (event: any) => void;
}

const ClearFilters = ({ disabled, action = () => {} }: ClearFilterButtonInterface) => {
    if (disabled) return null;
    return (
        <Box sx={{ position: 'relative' }}>
            <IconButton
                disabled={disabled}
                sx={{
                    height: 30,
                    width: 30,
                    backgroundColor: '#fff',
                    mr: '2px',
                    border: '1px solid rgba(0, 0, 0, 0.120)',
                }}
                onClick={action}
            >
                <FilterListOffOutlinedIcon fontSize="small" />
            </IconButton>
            {Boolean(!disabled) && (
                <Box
                    sx={{
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        background: 'red',
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                    }}
                ></Box>
            )}
        </Box>
    );
};

export default ClearFilters;
