import { Box, Typography } from '@mui/material';
import { Colors } from 'constants/colors';
import { useDevice } from 'hooks/useDevice';
import { useOutletContext } from 'react-router-dom';
import { StoresListContextInterface } from 'types/outlet_context_models';

const SalesMarker = () => {
    const { sx } = useDevice();
    const { string }: StoresListContextInterface = useOutletContext();
    return (
        <Box
            p={sx ? 0.45 : 0.5}
            sx={{
                width: sx ? 200 : 220,
                position: 'absolute',
                top: sx ? 30 : 33,
                right: -30,
                background: `linear-gradient(45deg, ${Colors.ORANGE} 20%, ${Colors.RED} 120%)`,
                zIndex: 10,
                transform: 'rotate(35deg)',
                display: 'flex',
                justifyContent: 'center',
                opacity: 0.55,
            }}
        >
            <Typography mr={sx ? 5 : 1} sx={{ color: Colors?.WHITE, fontSize: sx ? 14 : 16 }}>
                {string?.discounts_}
            </Typography>
        </Box>
    );
};

export default SalesMarker;
