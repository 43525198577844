import InfoDialog from 'components/organisms/Modals/InfoDialog';
import { LOGIN_ROUTE } from 'router/routes';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreConfig } from 'store_constants/useStoreConfig';

const SomethingWentWrong = ({ isOpen, setIsOpen, string }) => {
    const { storeCode } = useParams();
    const { REQUIRED_REGISTRATION } = useStoreConfig();
    const navigate = useNavigate();
    if (!isOpen) return null;

    return (
        <>
            <InfoDialog
                variant="error"
                link={null}
                string={string}
                onClose={() => {
                    if (REQUIRED_REGISTRATION) {
                        navigate(LOGIN_ROUTE?.root(storeCode, 'login'));
                    } else {
                        setIsOpen(null);
                        navigate('/');
                    }
                }}
                title={null}
                fullWidth
                onSubmit={() => {
                    if (REQUIRED_REGISTRATION) {
                        navigate(LOGIN_ROUTE?.root(storeCode, 'login'));
                    } else {
                        setIsOpen(null);
                        navigate('/');
                    }
                }}
                description={string?.something_went_wrong}
                closeAvailable
                component="bad request"
                content={null}
            />
        </>
    );
};

export default SomethingWentWrong;
