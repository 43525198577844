import { useState, useCallback } from 'react';
import { FormattedStore, UserDataInterface } from 'types/app_models';
import { useStoreConfig } from 'store_constants/useStoreConfig';
import { ViewModeType } from 'store_constants/types';

export interface AppStorageInterface {
    auth: boolean | null;
    setAuth: (newAuth: boolean) => void;
    apiToken: string | null;
    setApiToken: (token: string | null) => void;
    lang: string;
    setLang: (newLang: string) => void;
    viewMode: ViewModeType | null;
    setViewMode: (newViewMode: ViewModeType) => void;
    currentUserData: UserDataInterface | null;
    setCurrentUserData: (newData: UserDataInterface) => void;
    currentStoreData: FormattedStore | null;
    setCurrentStoreData: (newData: FormattedStore) => void;
    storesListData: FormattedStore[] | null;
    subscribeList: FormattedStore[] | null;
    setCurrentStoresListData: (newData: FormattedStore[]) => void;
    setSubscribeListData: (newData: FormattedStore[]) => void;
}

export const useAppStorage = (): AppStorageInterface => {
    const { APP_LANGUAGE } = useStoreConfig();
    const [lang, setLang] = useState<string>(APP_LANGUAGE);
    const [viewMode, setViewMode] = useState<ViewModeType | null>(null);
    const [auth, setAuth] = useState<boolean | null>(null);
    const [apiToken, setApiToken] = useState<string | null>(null);
    const [currentUserData, setCurrentUserData] = useState<UserDataInterface | null>(null);
    const [currentStoreData, setCurrentStoreData] = useState<FormattedStore | null>(null);
    const [storesListData, setStoresListData] = useState<FormattedStore[] | null>(null);
    const [subscribeList, setSubscribeList] = useState<FormattedStore[] | null>(null);

    const memoizedSetLang = useCallback((newLang: string) => setLang(newLang), []);
    const memoizedSetViewMode = useCallback((newViewMode: ViewModeType) => setViewMode(newViewMode), []);
    const memoizedSetAuth = useCallback((newAuth: boolean) => setAuth(newAuth), []);
    const memoizedSetApiToken = useCallback((token: string | null) => setApiToken(token), []);
    const memoizedSetCurrentUserData = useCallback((newData: UserDataInterface) => setCurrentUserData(newData), []);
    const memoizedSetCurrentStoreData = useCallback((newData: FormattedStore) => setCurrentStoreData(newData), []);
    const memoizedSetStoresListData = useCallback((newData: FormattedStore[]) => setStoresListData(newData), []);
    const memoizedSetSubscribeListData = useCallback((newData: FormattedStore[]) => setSubscribeList(newData), []);

    return {
        auth,
        setAuth: memoizedSetAuth,
        apiToken,
        setApiToken: memoizedSetApiToken,
        lang,
        setLang: memoizedSetLang,
        viewMode,
        setViewMode: memoizedSetViewMode,
        currentUserData,
        setCurrentUserData: memoizedSetCurrentUserData,
        currentStoreData,
        setCurrentStoreData: memoizedSetCurrentStoreData,
        storesListData,
        subscribeList,
        setCurrentStoresListData: memoizedSetStoresListData,
        setSubscribeListData: memoizedSetSubscribeListData,
    };
};
