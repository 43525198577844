import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import WelcomePage from 'layouts/WelcomeLayout/Welcome';
import { Suspense, useMemo, useState } from 'react';
import { ROUTES } from './routes';
import Home from 'layouts/Home/Home';
import { useAppStorage } from 'hooks/useAppStorage';
import { useUserApi } from 'api/useUserApi';
import { LangResInterface, useGetLanguage } from 'hooks/useGetLanguage';
import { useAddToCart } from 'layouts/hooks/useAddToCart';
import { useAddToFavorites } from 'layouts/hooks/useAddToFavorites';
import useImageStorage from 'layouts/hooks/useImageStorage';
import { useAddToCartDataInterface, useAddToFavoriteDataInterface } from 'types/app_models';
import { useStoreConfig } from 'store_constants/useStoreConfig';
import HomePage from 'pages/Home/HomePage';
import StoresLogic from 'StoresLogic';
import { useStoresApi } from 'api/useStoresApi';
import MainCatalog from 'layouts/Catalog/MainCatalog';
import Catalog from 'pages/Catalog/Catalog';
import ProductDetails from 'pages/ProductDetails/ProductDetails';
import PAGE_404 from 'pages/TechPages/404';
import Cart from 'pages/Cart/Cart';
import Favorites from 'pages/Favorites/Favorites';
import UserOrders from 'pages/Orders/UserOrders';
import UserProfile from 'pages/Profile/UserProfile';
import InformationPage from 'pages/Information/InformationPage';
import ContactsManagePage from 'pages/Contacts/ContactsManagePage';
import PAGE_401 from 'pages/TechPages/401';
import PAGE_403 from 'pages/TechPages/403';
import PAGE_500 from 'pages/TechPages/500';
import SecurityLayout from 'layouts/Security/Security';
import NewPassword from 'layouts/Security/NewPassword';
import Market from 'layouts/Market/Market';
import MarketPage from 'pages/Market/MarketPage';
// import SubscribePage from 'pages/Market/SubscribePage';

const StoresRouting = () => {
    const { STORE_NAME } = useStoreConfig();
    const [marketScrollPosition, setMarketScrollPosition] = useState(0);
    const {
        auth,
        setAuth,
        lang,
        setLang,
        viewMode,
        setViewMode,
        currentStoreData,
        setCurrentStoreData,
        currentUserData,
        setCurrentUserData,
        apiToken,
        setApiToken,
        setCurrentStoresListData,
        setSubscribeListData,
        storesListData,
        subscribeList,
    } = useAppStorage();

    const {
        refetch: fetchUserData,
        isFetching: isFetchingUser,
        error: userError,
    } = useUserApi().useGetUserData({
        storeCode: 'cocktail_store',
    });

    const { data: storeDataRes, isFetching: isStoreLoading } = useStoresApi().useGetStoreByCode({
        code: '',
    });

    const { data: storesListDataRes, isFetching: isStoresListLoading } = useStoresApi().useGetAllStores({
        count: 100,
        page: 0,
    });

    const {
        data: subscribeListDataRes,
        isFetching: isSubscribeListLoading,
        refetch: refreshSubscriptionsList,
    } = useStoresApi().useGetAllFavoritesStores({
        auth,
    });

    const { currentLanguage }: LangResInterface = useGetLanguage({ lang, storeName: STORE_NAME });
    const cart: useAddToCartDataInterface = useAddToCart({ loadingUser: isFetchingUser });
    const favorites: useAddToFavoriteDataInterface = useAddToFavorites({ loadingUser: isFetchingUser });
    const { handleSaveImage, savedImages } = useImageStorage();

    const memoizedAppLogic = useMemo(
        () => ({
            auth,
            setAuth,
            setLang,
            setViewMode,
            setCurrentStoreData,
            userData: { currentUserData, isFetchingUser, setCurrentUserData, fetchUserData, userError },
            lang,
            viewMode,
            storeDataRes,
            isStoreLoading,
            apiToken,
            setApiToken,
            storesListDataRes,
            isStoresListLoading,
            setCurrentStoresListData,
            setSubscribeListData,
            subscribeListDataRes,
            isSubscribeListLoading,
        }),
        [lang, auth, storeDataRes, currentUserData, viewMode, storesListDataRes, subscribeListDataRes] // eslint-disable-line
    );

    StoresLogic(memoizedAppLogic);

    const router = createBrowserRouter([
        {
            path: '/',
            errorElement: <PAGE_404 />,
            loader: () => <div>Loading...</div>,
            element: <WelcomePage />,
        },

        {
            path: `${ROUTES?.SECURITY}/:storeCode/:formType`,
            errorElement: <PAGE_404 />,
            loader: () => <div>Loading...</div>,
            element: (
                <Suspense fallback={<div>Loading...</div>}>
                    <SecurityLayout
                        lang={lang}
                        setLang={setLang}
                        setAuth={setAuth}
                        store={currentStoreData}
                        currentLanguage={currentLanguage}
                        setApiToken={setApiToken}
                    />
                </Suspense>
            ),
        },
        {
            path: `${ROUTES?.NEW_PASSWORD}/:storeCode/:tokenId`,
            errorElement: <PAGE_404 />,
            loader: () => <div>Loading...</div>,
            element: (
                <NewPassword
                    lang={lang}
                    setLang={setLang}
                    setAuth={setAuth}
                    currentLanguage={currentLanguage}
                    storesList={storesListData}
                />
            ),
        },
        {
            path: ROUTES?.HOME,
            errorElement: <PAGE_404 />,
            loader: () => <div>Loading...</div>,
            element: (
                <Suspense fallback={<div>Loading...</div>}>
                    <Home
                        lang={lang}
                        setLang={setLang}
                        auth={auth}
                        setAuth={setAuth}
                        userData={{
                            currentUserData,
                            isFetchingUser,
                            setCurrentUserData,
                            fetchUserData,
                            userError,
                        }}
                        currentLanguage={currentLanguage}
                        handleSaveImage={handleSaveImage}
                        savedImages={savedImages}
                        apiToken={apiToken}
                        setApiToken={setApiToken}
                        subscribeList={subscribeList}
                    />
                </Suspense>
            ),
            children: [
                {
                    path: '',
                    errorElement: <PAGE_404 />,
                    loader: () => <div>Loading...</div>,
                    element: (
                        <Suspense fallback={<div>Loading...</div>}>
                            <HomePage />
                        </Suspense>
                    ),
                },
                {
                    path: 'profile',
                    errorElement: <PAGE_404 />,
                    loader: () => <div>Loading...</div>,
                    element: (
                        <Suspense fallback={<div>Loading...</div>}>
                            <UserProfile />
                        </Suspense>
                    ),
                },
                {
                    path: 'market_contacts',
                    errorElement: <PAGE_404 />,
                    loader: () => <div>Loading...</div>,
                    element: (
                        <Suspense fallback={<div>Loading...</div>}>
                            <ContactsManagePage />
                        </Suspense>
                    ),
                },
                {
                    path: 'info',
                    errorElement: <PAGE_404 />,
                    loader: () => <div>Loading...</div>,
                    element: (
                        <Suspense fallback={<div>Loading...</div>}>
                            <InformationPage />
                        </Suspense>
                    ),
                },
                {
                    path: '*',
                    errorElement: <PAGE_404 />,
                    loader: () => <div>Loading...</div>,
                    element: <Navigate to={'/'} replace />,
                },
            ],
        },
        {
            path: ROUTES?.MARKET,
            errorElement: <PAGE_404 />,
            loader: () => <div>Loading...</div>,
            element: (
                <Suspense fallback={<div>Loading...</div>}>
                    <Market
                        storesList={storesListData}
                        subscribeList={subscribeList}
                        lang={lang}
                        setLang={setLang}
                        auth={auth}
                        setAuth={setAuth}
                        userData={{
                            currentUserData,
                            isFetchingUser,
                            setCurrentUserData,
                            fetchUserData,
                            userError,
                        }}
                        currentLanguage={currentLanguage}
                        handleSaveImage={handleSaveImage}
                        savedImages={savedImages}
                        apiToken={apiToken}
                        setApiToken={setApiToken}
                        refreshSubscriptionsList={refreshSubscriptionsList}
                        marketScrollPosition={marketScrollPosition}
                        setMarketScrollPosition={setMarketScrollPosition}
                        isStoresListLoading={isStoresListLoading}
                    />
                </Suspense>
            ),
            children: [
                {
                    path: '',
                    errorElement: <PAGE_404 />,
                    loader: () => <div>Loading...</div>,
                    element: (
                        <Suspense fallback={<div>Loading...</div>}>
                            <MarketPage />
                        </Suspense>
                    ),
                },
                // {
                //     path: 'subscriptions',
                //     errorElement: <PAGE_404 />,
                //     loader: () => <div>Loading...</div>,
                //     element: (
                //         <Suspense fallback={<div>Loading...</div>}>
                //             <SubscribePage />
                //         </Suspense>
                //     ),
                // },
                {
                    path: '*',
                    errorElement: <PAGE_404 />,
                    loader: () => <div>Loading...</div>,
                    element: <Navigate to={'/'} replace />,
                },
            ],
        },
        {
            path: ROUTES?.STORE,
            errorElement: <PAGE_404 />,
            loader: () => <div>Loading...</div>,
            element: (
                <Suspense fallback={<div>Loading...</div>}>
                    <MainCatalog
                        storesList={storesListData || null}
                        lang={lang}
                        setLang={setLang}
                        viewMode={viewMode}
                        setViewMode={setViewMode}
                        auth={auth}
                        setAuth={setAuth}
                        userData={{
                            currentUserData,
                            isFetchingUser,
                            setCurrentUserData,
                            fetchUserData,
                            userError,
                        }}
                        cart={cart}
                        favorites={favorites}
                        currentLanguage={currentLanguage}
                        handleSaveImage={handleSaveImage}
                        savedImages={savedImages}
                        apiToken={apiToken}
                        setApiToken={setApiToken}
                    />
                </Suspense>
            ),
            children: [
                {
                    path: ':storeCode',
                    errorElement: <PAGE_404 />,
                    loader: () => <div>Loading...</div>,
                    element: (
                        <Suspense fallback={<div>Loading...</div>}>
                            <Catalog />
                        </Suspense>
                    ),
                },
                {
                    path: ':storeCode/product/:productId/model/:modelSku',
                    errorElement: <PAGE_404 />,
                    loader: () => <div>Loading...</div>,
                    element: (
                        <Suspense fallback={<div>Loading...</div>}>
                            <ProductDetails />
                        </Suspense>
                    ),
                },
                {
                    path: ':storeCode/cart',
                    errorElement: <PAGE_404 />,
                    loader: () => <div>Loading...</div>,
                    element: (
                        <Suspense fallback={<div>Loading...</div>}>
                            <Cart />
                        </Suspense>
                    ),
                },
                {
                    path: ':storeCode/favorites',
                    errorElement: <PAGE_404 />,
                    loader: () => <div>Loading...</div>,
                    element: (
                        <Suspense fallback={<div>Loading...</div>}>
                            <Favorites />
                        </Suspense>
                    ),
                },
                {
                    path: ':storeCode/orders',
                    errorElement: <PAGE_404 />,
                    loader: () => <div>Loading...</div>,
                    element: (
                        <Suspense fallback={<div>Loading...</div>}>
                            <UserOrders />
                        </Suspense>
                    ),
                },

                {
                    path: ':storeCode/contacts',
                    errorElement: <PAGE_404 />,
                    loader: () => <div>Loading...</div>,
                    element: (
                        <Suspense fallback={<div>Loading...</div>}>
                            <ContactsManagePage />
                        </Suspense>
                    ),
                },
                {
                    path: '*',
                    errorElement: <PAGE_404 />,
                    loader: () => <div>Loading...</div>,
                    element: <Navigate to={'/'} replace />,
                },
            ],
        },

        {
            path: ROUTES?.PAGE_401,
            loader: () => <div>Loading...</div>,
            element: (
                <Suspense fallback={<div>Loading...</div>}>
                    <PAGE_401 />
                </Suspense>
            ),
        },
        {
            path: ROUTES?.PAGE_403,
            loader: () => <div>Loading...</div>,
            element: (
                <Suspense fallback={<div>Loading...</div>}>
                    <PAGE_403 />
                </Suspense>
            ),
        },
        {
            path: ROUTES?.PAGE_404,
            loader: () => <div>Loading...</div>,
            element: (
                <Suspense fallback={<div>Loading...</div>}>
                    <PAGE_404 />
                </Suspense>
            ),
        },
        {
            path: ROUTES?.PAGE_500,
            loader: () => <div>Loading...</div>,
            element: (
                <Suspense fallback={<div>Loading...</div>}>
                    <PAGE_500 />
                </Suspense>
            ),
        },
        {
            path: '*',
            errorElement: <PAGE_404 />,
            loader: () => <div>Loading...</div>,
            element: <Navigate to={'/'} replace />,
        },
    ]);

    return <RouterProvider router={router} />;
};

export default StoresRouting;
