import { GenderType, MarketPlaceType, StoreType, ViewModeType } from 'store_constants/types';

export const RETAIL_ALBERTO_BINI = {
    storage_key: 'ab_ua-rt_my',
    active: true, // Поле которое запускает магазин true - работает false - остановлен / по тех. причина
    status: null,
    required_registration: false,
    URL: ['https://albertobini-brandeurope.com'],
    web_lang: 'uk', // html lang
    store_name: 'ALBERTO BINI',
    store_code: 'albertobini_retailer',
    base_url: 'https://kremen-belts.com/api',
    app_lang: 'ua',
    supported_countries: null,
    telegram_sender: true,
    links: [],
    managers: [
        {
            firstName: 'Cocktail Manager',
            lastName: '',
            emailAddress: 'salesnest.info@gmail.com',
            options: { manager: true },
            contacts: {
                emailAddress: 'salesnest.info@gmail.com',
                phone: '+380668652127',
                viber: '',
                whatsapp: '+380668652127',
                telegram: 'SNBusinessApp',
            },
        },
    ],
    options: {
        discounts: true,
        webUrl: 'https://albertobini-brandeurope.com',
        telegram_bot: [
            {
                token: '7115237316:AAFgbrVadLWMkPt3TEdmravEa37jO9N3WSQ',
                chatId: '480774886',
            },
        ],
        product_image_options: { width: 6, height: 9 },
        main_price_multiplication: 1,
        sale_price_multiplication: 1,
        currency_multiplication: 1,
        retail_price_multiplication: 1,
        min_items_to_buy: 1,
        store_type: StoreType?.sales,
        home_page_active: true,
        informative_page_active: true,
        custom_currency: null,
        custom_logo: true,
        orderPriority: 10,
        plan_options: {
            prices: true,
            sizes: true,
            contacts: true,
            feedback: true,
            categories: true,
            productShare: true,
            cart: true,
            favorites: true,
            tableSizes: true,
            marketPlace: { isShown: true, type: MarketPlaceType?.drop },
        },
    },
    web_head_data: {
        store_title: 'Alberto Bini Wholesales Catalog | Alberto Bini Оптовий Каталог',
        store_description: `Ласкаво просимо до Alberto Bini — світу вишуканого жіночого верхнього одягу! Дослідіть наш широкий вибір високоякісних стильних пальт, тренчів та курток. Погрузіться в унікальний стиль та комфорт з Alberto Bini!  Welcome to Alberto Bini — the world of exquisite women's outerwear! Explore our wide selection of high-quality stylish coats, trenches, and jackets. Immerse yourself in unique style and comfort with Alberto Bini!`,
        google_analytics_id: 'G-SPMQE8GHGM',
        google_ads_id: '',
        conversion_id: '',
        store_logo: 'alberto_bini_logo.png',
        store_poster: 'alb.jpeg',
        keywords: {
            en: "women's outerwear, wholesale, retail, great prices, high quality, worldwide shipping",
            ru: 'женская верхняя одежда, оптом, в розницу, хорошие цены, высочайшее качество, продажа по всему миру',
            kk: 'әйелдер киімі, көтерме сауда, бөлшек сауда, жақсы бағалар, жоғары сапа, дүние жүзі бойынша жеткізу',
            uk: 'жіночий верхній одяг, оптом, у роздріб, гарні ціни, висока якість, продаж по всьому світу',
            pl: 'odzież damska, hurt, detal, dobre ceny, wysoka jakość, sprzedaż na całym świecie',
            cs: 'dámské svrchní oděvy, velkoobchod, maloobchod, dobré ceny, vysoká kvalita, prodej po celém světě',
            es: 'ropa exterior para mujeres, al por mayor, al por menor, buenos precios, alta calidad, venta en todo el mundo',
            fr: "vêtements d'extérieur pour femmes, en gros, au détail, bons prix, haute qualité, vente dans le monde entier",
        },
    },
    user_options: { view_mode: ViewModeType?.card },
    alerts: [
        // { name: AppAlertNameType?.REGISTRATION, description: '', subject: AppAlertSubjectType?.PROMO, active: true },
    ],
    store: {
        bannerUrl: require(`../banners/alberto_bini_retail.webp`),
        descriptions: [
            {
                language: 'ua',
                description:
                    'Магазин Alberto Bini пропонує широкий асортимент стильного жіночого одягу, виконаного з високоякісних натуральних матеріалів. Ми ставимо акцент на індивідуальність та комфорт, прагнучи створити унікальні вироби, що підходять для будь-якої нагоди. У нашій колекції ви знайдете речі, які поєднують тепло і елегантність, створюючи бездоганний образ.',
            },
            {
                language: 'en',
                description:
                    "Alberto Bini store offers a wide range of stylish women's clothing made from high-quality natural materials. We focus on individuality and comfort, striving to create unique items suitable for any occasion. In our collection, you will find pieces that combine warmth and elegance, creating a flawless look.",
            },
        ],
        types: [3, 5, 6, 12, 13, 14, 17, 31, 45, 1000],
        gender: [GenderType?.FEMALE],
        rank: 1,
    },
};
