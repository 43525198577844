import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useDevice } from 'hooks/useDevice';
import Grid from '@mui/material/Unstable_Grid2';
import { StoresListContextInterface } from 'types/outlet_context_models';
import { STORE_ROUTE } from 'router/routes';
import { FormattedStore } from 'types/app_models';
interface Props {
    CardDetails;
    opacity;
    data: FormattedStore;
}

const LargeStoreCard = ({ CardDetails, opacity, data, ...rest }: Props) => {
    const navigate = useNavigate();
    const cardRef = useRef<HTMLElement>(null);
    const { setMarketScrollPosition }: StoresListContextInterface = useOutletContext();
    const { m, l, sx } = useDevice();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => setLoading(false), 100);
    }, []);

    const getGridValue = () => {
        if (m) return 12;
        if (l) return 6;
        return 4;
    };

    return (
        <Grid
            className="StoreMainCard"
            onClick={() => {
                navigate(STORE_ROUTE?.root(data?.code));
            }}
            xs={getGridValue()}
            sx={{ opacity: loading ? 0 : 1 }}
            {...rest}
        >
            <Box
                ref={cardRef}
                sx={{
                    borderRadius: sx ? 3 : 4,

                    width: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    position: 'relative',
                    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0.55)), url(${data?.store?.bannerUrl})`, // Градиент поверх изображения
                    backgroundSize: sx ? 393 : 555, // Масштабирование изображения
                    backgroundRepeat: 'no-repeat', // Повторение изображения
                    backgroundPosition: 'cover',
                    boxShadow: '0 0 5px 2px #7c7c7c40',
                }}
                onClick={() => {
                    setMarketScrollPosition(cardRef?.current?.offsetTop);
                }}
            >
                {CardDetails()}
                <Box
                    ref={cardRef}
                    sx={{
                        width: sx ? 115 : 150,
                        height: '100%',
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        background: '#ffffffdf',
                        zIndex: 1,
                    }}
                ></Box>
            </Box>
        </Grid>
    );
};

export default LargeStoreCard;
