export const HOME_PAGE = {
    en: {
        customer_description_1:
            'Discover unique Ukrainian and international brands in our app, each with its own distinctive atmosphere.',
        customer_description_2:
            'Stay updated on new collections, subscribe to your favorite labels, and refresh your wardrobe with just a few clicks.',
        customer_description_3:
            'Maximum comfort, minimal hassle—everything is designed to make your shopping experience quick, easy, and inspiring.',
    },

    ua: {
        customer_description_1:
            'Відкрийте світ унікальних українських і зарубіжних брендів у нашому додатку, кожен з яких має свою неповторну атмосферу.',
        customer_description_2:
            'Слідкуйте за новими колекціями, підписуйтеся на улюблені марки та оновлюйте гардероб за кілька кліків.',
        customer_description_3:
            'Максимальний комфорт, мінімум клопоту — все створено, щоб зробити ваш шопінг швидким, легким і надихаючим.',
    },
};
