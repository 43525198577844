import { Box } from '@mui/material';
import HeaderNavButton from 'components/atoms/Buttons/HeaderNavButton';
import LanguageButton from 'components/molecules/ToolsButtons/LanguageButton';
import { useDevice } from 'hooks/useDevice';
import HomeIcon from '@mui/icons-material/Home';
import { Color } from 'constants/colors';
import ProfileMenu from 'components/molecules/ToolsButtons/ProfileMenu';
import { HOME_ROUTE, MARKET_ROUTE } from 'router/routes';
import LocalBarTwoToneIcon from '@mui/icons-material/LocalBarTwoTone';
import CustomLogo from 'components/atoms/Logo/CustomLogo';
// import BookmarkIcon from '@mui/icons-material/Bookmark';
// import { useNavigate } from 'react-router-dom';

interface HeaderInterface {
    headerHeight;
    appXPadding;
    string;
    lang;
    setLang;
    handleOpenDialog;
    auth;
    user;
    subscribeList;
}

const HomeHeader = ({
    headerHeight,
    appXPadding,
    string,
    lang,
    setLang,
    handleOpenDialog,
    auth,
    user,
    subscribeList,
}: HeaderInterface) => {
    const { sx } = useDevice();
    // const navigate = useNavigate();

    return (
        <Box
            px={appXPadding}
            sx={{
                height: `${headerHeight}px`,
                position: 'fixed',
                width: '100%',
                left: 0,
                top: 0,
                zIndex: 4000,
                backgroundColor: Color?.PRIMARY_LIGHT,
                overflow: 'hidden',
            }}
        >
            <Box
                sx={{
                    height: `${headerHeight}px`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CustomLogo />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <HeaderNavButton
                        title={string?.home}
                        path={HOME_ROUTE?.root()}
                        icon={() => <HomeIcon />}
                        isShown={!sx}
                    />
                    <HeaderNavButton
                        title={string?.stores}
                        path={MARKET_ROUTE?.root()}
                        icon={() => <LocalBarTwoToneIcon />}
                        isShown={!sx}
                    />
                    {/* <HeaderNavButton
                        title={string?.subscribes}
                        path={MARKET_ROUTE?.subscribes()}
                        icon={() => <BookmarkIcon />}
                        isShown={!sx}
                        action={() => {
                            navigate(MARKET_ROUTE?.subscribes());
                        }}
                        badgeCount={subscribeList?.length}
                    /> */}

                    {!sx && (
                        <ProfileMenu
                            auth={auth}
                            string={string}
                            headerHeight={headerHeight}
                            user={user}
                            handleOpenDialog={handleOpenDialog}
                            childPath={['orders', 'profile', 'info', 'contacts']}
                        />
                    )}
                    <LanguageButton setLang={setLang} string={string} lang={lang} storeLanguages={['en', 'ua']} />
                </Box>
            </Box>
        </Box>
    );
};

export default HomeHeader;
