import { Box } from '@mui/material';
import HeaderNavButton from 'components/atoms/Buttons/HeaderNavButton';
import LanguageButton from 'components/molecules/ToolsButtons/LanguageButton';
import { useDevice } from 'hooks/useDevice';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ProfileMenu from 'components/molecules/ToolsButtons/ProfileMenu';
import { FormattedStore, useAddToCartDataInterface, useAddToFavoriteDataInterface } from 'types/app_models';
import { Color, Colors } from 'constants/colors';
import { MARKET_ROUTE, STORE_ROUTE } from 'router/routes';
import LocalBarTwoToneIcon from '@mui/icons-material/LocalBarTwoTone';
import { useParams } from 'react-router-dom';
import GridViewIcon from '@mui/icons-material/GridView';
import CustomLogo from 'components/atoms/Logo/CustomLogo';
interface HeaderInterface {
    headerHeight;
    appXPadding;
    string;
    lang: string | null;
    setLang;
    auth;
    logo;
    store: FormattedStore | null;
    cart: useAddToCartDataInterface;
    favorites: useAddToFavoriteDataInterface;
    user;
    handleOpenDialog: (val) => void;
}

const Header = ({
    headerHeight,
    appXPadding,
    string,
    lang,
    setLang,
    auth,
    user,
    handleOpenDialog,
    logo,
    store,
    cart,
    favorites,
}: HeaderInterface) => {
    const { storeCode } = useParams();
    const { sx } = useDevice();

    return (
        <Box
            px={appXPadding}
            sx={{
                height: `${headerHeight}px`,
                borderBottom: '1px solid',
                borderColor: Colors?.GRAY_300,
                position: 'fixed',
                width: '100%',
                left: 0,
                top: 0,
                zIndex: 3000,
                backgroundColor: Color?.PRIMARY_LIGHT,
                overflow: 'hidden',
            }}
        >
            <Box
                sx={{
                    height: `${headerHeight}px`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CustomLogo />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <HeaderNavButton
                        title={string?.stores}
                        path={MARKET_ROUTE?.root()}
                        icon={() => <LocalBarTwoToneIcon />}
                        isShown
                        isActive={window.location.pathname.includes(`market`)}
                    />
                    <HeaderNavButton
                        title={string?.catalog}
                        path={STORE_ROUTE?.root(storeCode)}
                        icon={() => <GridViewIcon />}
                        isShown={!sx}
                        childPath={['product']}
                    />
                    <HeaderNavButton
                        path={STORE_ROUTE?.favorites(storeCode)}
                        title={string?.favorites}
                        icon={() => <FavoriteIcon />}
                        isShown={!sx}
                        badgeCount={favorites?.favoriteItems?.filter(el => el?.storeCode === storeCode)?.length}
                    />

                    <HeaderNavButton
                        path={STORE_ROUTE?.cart(storeCode)}
                        title={string?.cart}
                        icon={() => <ShoppingCartIcon />}
                        isShown={!sx}
                        badgeCount={cart?.cartItems?.filter(el => el?.storeCode === storeCode)?.length}
                    />

                    {!sx && (
                        <ProfileMenu
                            auth={auth}
                            string={string}
                            headerHeight={headerHeight}
                            user={user}
                            handleOpenDialog={handleOpenDialog}
                            childPath={['orders', 'profile', 'info', 'contacts']}
                        />
                    )}
                    <LanguageButton
                        setLang={setLang}
                        string={string}
                        lang={lang}
                        storeLanguages={store?.supportedLanguages?.map(el => {
                            return el?.code;
                        })}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default Header;
