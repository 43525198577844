import { Box, Typography } from '@mui/material';
import { Color, Colors } from 'constants/colors';
import { useDevice } from 'hooks/useDevice';
import ImageComponent from '../swiper/SwiperImage';
import PublicIcon from '@mui/icons-material/Public';

const StoreHeaderCard = ({ store, lang }) => {
    const { sx } = useDevice();
    return (
        <Box
            p={sx ? 1 : 2}
            sx={{
                borderRadius: sx ? 3 : 4,
                width: '100%',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                boxShadow: '0 0 5px 2px #7c7c7c40',
                m: '0 auto',
                mb: sx ? 2 : 3,
                background: `linear-gradient(45deg, ${Colors.GRAY_300} 20%, ${Colors.GRAY_100} 120%)`,
                gap: 1,
            }}
        >
            <Box
                mb={sx ? 0 : 1}
                sx={{
                    display: 'flex',
                    alignItems: sx ? 'flex-start' : 'center',
                    flexDirection: sx ? 'column' : 'row',
                    gap: 1.25,
                    width: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', gap: 1.5 }}>
                    <Box
                        sx={{
                            minWidth: sx ? 60 : 80,
                            width: sx ? 60 : 80,
                            height: sx ? 60 : 80,
                            borderRadius: 4,
                            overflow: 'hidden',
                            border: `1px solid ${Colors.GRAY_300}`,
                            boxShadow: '0 0 5px 2px #cecece5e',
                            background: Colors.WHITE,
                        }}
                    >
                        <ImageComponent
                            imageUrl={
                                store?.logo?.path.length > 1 ? store.logo.path : require('assets/img/cut_main_log.png')
                            }
                            wrapperHeight="100%"
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                            gap: 0.5,
                        }}
                    >
                        <Typography
                            sx={{
                                borderRadius: 1,
                                borderTopLeftRadius: 12,
                                lineHeight: 1,
                                fontSize: sx ? 26 : 42,
                                color: Colors.BLACK,
                                fontFamily: 'Serif',
                                fontWeight: 700,
                            }}
                            onClick={() => {
                                if (store?.options?.webUrl) window.open(store.options.webUrl, '_blank');
                            }}
                        >
                            {store?.name?.trim()}
                        </Typography>
                        {store?.options?.webUrl && (
                            <Box sx={{ display: 'flex', gap: 0.25, alignItems: 'center' }}>
                                <PublicIcon fontSize={sx ? 'small' : 'medium'} sx={{ color: Color?.PRIMARY }} />
                                <Typography
                                    onClick={() => {
                                        window.open(store?.options?.webUrl || '/', '_blank', 'noopener,noreferrer');
                                    }}
                                    sx={{
                                        fontSize: sx ? 12 : 18,
                                        color: Color?.PRIMARY,
                                        fontWeight: 500,
                                        cursor: 'pointer',
                                    }}
                                >
                                    {store.options.webUrl}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>

            <Box pr={sx ? 0 : 1} sx={{ display: 'flex', gap: sx ? 1 : 2 }}>
                <Box p={sx ? 0.5 : 1}>
                    <Typography
                        sx={{
                            color: Colors.GRAY_900,
                            fontWeight: 400,
                            lineHeight: 1.05,
                            fontSize: sx ? 12 : 18,
                        }}
                    >
                        {store?.store?.descriptions?.find(el => el.language === lang)?.description}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default StoreHeaderCard;
